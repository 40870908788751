import {LANG_SETTING} from '../settings';
import {UNSUPPORTED_LANG, LANGUAGE_CODE_STANDARD_LENGTH} from '../const';
import {DICTIONARY} from '../dictionary';

export const getModuleLanguage = () => {
    const pageLang = document.documentElement.lang;
    const settingLanguages = Object.keys(LANG_SETTING);
    const constLanguages = Object.keys(DICTIONARY);

    const siteLang = pageLang.length === LANGUAGE_CODE_STANDARD_LENGTH
        ? pageLang.toLowerCase()
        : pageLang.substring(0, LANGUAGE_CODE_STANDARD_LENGTH);

    return (!settingLanguages.includes(siteLang) || !constLanguages.includes(siteLang))
        ? UNSUPPORTED_LANG
        : siteLang;
};

export const setModuleLanguage = () => {
    window.TL_PRICE_MODULE_LANG = getModuleLanguage();
};
