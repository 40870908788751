import dayjs from 'dayjs';
import {
    UNSUPPORTED_LANG,
    LOCAL_STORAGE_CACHE_MEASURE,
    LOCAL_STORAGE_CACHE_VALUE, DEMO_MODE,
} from '../const';

export const isDemoMode = () => {
    return typeof tlPriceLoadMode !== 'undefined' && tlPriceLoadMode === DEMO_MODE;
};

export const isUnsupportedLanguage = () => {
    return TL_PRICE_MODULE_LANG === UNSUPPORTED_LANG;
};

export const reverseString = (text) => {
    return text.split('').reverse().join('');
};

export const getNowTimeStamp = () => {
    return dayjs().unix();
};

export const getExpireTime = () => {
    return dayjs().add(LOCAL_STORAGE_CACHE_VALUE, LOCAL_STORAGE_CACHE_MEASURE).unix();
};

export const getFormatDate = (date, format) => {
    return dayjs(date).format(format);
};

export const getLinkParamObject = (hotel, room, date, nights, adults, offer) => {
    return {hotel, room, date, nights, adults, offer};
};
