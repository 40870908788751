import {HOTEL_ATTRIBUTE, MULTI_ROOM_TYPE_DELIMITER} from '../const';
import {isDemoMode} from '../utils/tools';
import {getFormatPrice} from '../modules/format-price';
import {getLangDate} from '../modules/lang-date';
import {getLangMinLos} from '../modules/lang-minlos';
import {getLink} from '../modules/link';
import {getLangOffer} from '../modules/lang-offer';
import {setModuleLanguage} from '../utils/module-language';
import {ModuleElement as ModuleElementModel} from '../model/module-element';
import {Prices as PricesModel} from '../model/prices';
import {PriceInfo as PriceInfoModel} from '../model/price-info';

export class SitePricesPlacement {
    constructor(prices, selector) {
        this._getAllPricesInfo(prices);
        this._selectModuleElements(selector);

        setModuleLanguage();
    }

    placePrices() {
        this._checkEachElement(() => {
            if (!this._isPriceInfoForElement()) return;

            if (isDemoMode()) {
                this._setRestoreStatusToElement();
                this._setFillStatusToElement();
                this._setOfferCodeToElement();
            }

            if (this._isSetDateToElement()) return;

            if (this._isSetMinLosToElement()) return;

            if (this._isSetOfferNameToElement()) return;

            if (this._isSetLinkToElement()) return;

            if (this._hasElementCurrency()) {
                this._setPriceToElement();
            }
        });
    }

    _getAllPricesInfo(prices) {
        this._pricesModel = new PricesModel(prices);
    }

    _selectModuleElements(selector) {
        this._elements = selector !== undefined && selector !== ''
            ? document.querySelectorAll(`${selector}, ${selector} [${HOTEL_ATTRIBUTE}]`)
            : document.querySelectorAll(`[${HOTEL_ATTRIBUTE}]`);
    }

    _checkEachElement(callback) {
        this._elements.forEach((element) => {
            this._elementModel = new ModuleElementModel(element);

            callback();
        });
    }

    _isPriceInfoForElement() {
        const priceInfoModel = this._hasElementRoom()
            ? this._getRoomsMinPriceInfo()
            : this._getHotelPriceInfo();

        if (this._isCorrectPriceInfo(priceInfoModel)) {
            this._setPriceInfoForElement(priceInfoModel);
            return true;
        }

        return false;
    }

    _isCorrectPriceInfo(priceInfoModel) {
        return priceInfoModel instanceof PriceInfoModel;
    }

    _setPriceInfoForElement(priceInfoModel) {
        this._priceInfoModel = priceInfoModel;
    }

    _getRoomPriceInfo(roomType) {
        const hotel = this._elementModel.getHotel();
        const adults = this._elementModel.getAdults();

        return (adults)
            ? this._pricesModel.getAdultsPriceInfo(hotel, roomType, adults)
            : this._pricesModel.getRoomMinPriceInfo(hotel, roomType);
    }

    _getHotelPriceInfo() {
        try {
            return this._pricesModel.getHotelMinPriceInfo(this._elementModel.getHotel());
        } catch (err) {
            return null;
        }
    }

    _getRoomsMinPriceInfo() {
        const roomTypes = this._elementModel.getRoomType().split(MULTI_ROOM_TYPE_DELIMITER);
        let minPriceInfoModel = null;

        roomTypes.forEach((roomType) => {
            try {
                const priceInfoModel = this._getRoomPriceInfo(roomType);

                if (
                    !(minPriceInfoModel instanceof PriceInfoModel)
                    || (
                        minPriceInfoModel instanceof PriceInfoModel
                        && minPriceInfoModel.getPrice() > priceInfoModel.getPrice()
                    )
                ) {
                    minPriceInfoModel = priceInfoModel;
                }
            } catch (err) {
                return null;
            }
        });

        return minPriceInfoModel;
    }

    _setRestoreStatusToElement() {
        if (this._elementModel.hasRestoreStatus()) {
            this._elementModel.setRestoreStatus(this._priceInfoModel.getRestoreStatus());
        }
    }

    _setFillStatusToElement() {
        if (this._elementModel.hasFillStatus()) {
            this._elementModel.setFillStatus(this._priceInfoModel.getFillStatus());
        }
    }

    _setOfferCodeToElement() {
        if (this._elementModel.isOfferCodeField()) {
            this._elementModel.setContent(this._priceInfoModel.getRatePlaneCode());
        }
    }

    _isSetDateToElement() {
        if (this._elementModel.isDate()) {
            const date = this._priceInfoModel.getData();

            if (date) {
                this._elementModel.setDataAttribute(date);
                this._elementModel.setContent(getLangDate(date));
            }

            return true;
        }

        return false;
    }

    _isSetMinLosToElement() {
        if (this._elementModel.isMinLos()) {
            const minlos = this._priceInfoModel.getMinLos();

            if (minlos) {
                this._elementModel.setMinLosAttribute(minlos);
                this._elementModel.setContent(getLangMinLos(minlos));
            }

            return true;
        }

        return false;
    }

    _isSetOfferNameToElement() {
        if (this._elementModel.isOfferName()) {
            const offerCode = this._priceInfoModel.getRatePlaneCode();
            const offerName = this._priceInfoModel.getRatePlaneName();

            if (offerCode && offerName) {
                this._elementModel.setOfferAttribute(offerCode);
                this._elementModel.setContent(getLangOffer(offerName));
            }

            return true;
        }

        return false;
    }

    _isSetLinkToElement() {
        if (this._elementModel.isLink()) {
            const linkOptions = this._elementModel.getLinkParams();
            const bookUrl = this._elementModel.getLinkBookUrl();

            this._elementModel.setLink(getLink(linkOptions, bookUrl, this._elementModel, this._priceInfoModel));

            return true;
        }

        return false;
    }

    _hasElementCurrency() {
        return Boolean(this._elementModel.getCurrency());
    }

    _hasElementRoom() {
        return Boolean(this._elementModel.getRoomType());
    }

    _setPriceToElement() {
        const currency = this._elementModel.getCurrency();
        const price = this._priceInfoModel.getPriceByCurrency(currency);

        if (currency && price) {
            this._elementModel.setContent(getFormatPrice(price));
        }
    }
}
