import {DEFAULT_DATE_FORMAT, DEFAULT_LANG, PLACEHOLDER} from '../const';
import {getFormatDate, isDemoMode, isUnsupportedLanguage} from '../utils/tools';
import {LANG_SETTING} from '../settings';
import {DICTIONARY} from '../dictionary';

export const setLocaleMonthName = (date, formattedDate) => {
    const enMonthName = getFormatDate(date, PLACEHOLDER.MonthFormat);
    const localeMonthName = DICTIONARY[TL_PRICE_MODULE_LANG].month[enMonthName];

    return formattedDate.replace(enMonthName, localeMonthName);
};

export const getDateText = (date) => {
    const {DateFormat, DateText} = LANG_SETTING[TL_PRICE_MODULE_LANG];
    const dateFormatted = TL_PRICE_MODULE_LANG !== DEFAULT_LANG
        ? setLocaleMonthName(date, getFormatDate(date, DateFormat))
        : getFormatDate(date, DateFormat);

    return DateText.replace(PLACEHOLDER.DateFormat, dateFormatted);
};

export const getLangDate = (date) => {
    if (isDemoMode() || isUnsupportedLanguage()) {
        return (date) ? getFormatDate(date, DEFAULT_DATE_FORMAT) : date;
    }

    return (date) ? getDateText(date) : date;
};
