/* global */

export const DEMO_MODE = 'demo';
export const PRICES_CACHE_FILE = window.tlTestFrontMode
    ? 'http://test-scripts.local:8080/tl-price-load/cache/price.json'
    : '/tl-price-load/cache/price.json';

/* module attributes */

export const HOTEL_ATTRIBUTE = 'data-tl-hotel-id';
export const ROOM_TYPE_ATTRIBUTE = 'data-tl-room-type';
export const ADULTS_ATTRIBUTE = 'data-tl-adults';
export const CURRENCY_ATTRIBUTE = 'data-tl-currency';
export const DATE_ATTRIBUTE = 'data-tl-date';
export const MIN_LOS_ATTRIBUTE = 'data-tl-minlos';
export const OFFER_CODE_ATTRIBUTE = 'data-tl-offer-code';
export const OFFER_NAME_ATTRIBUTE = 'data-tl-offer-name';
export const LINK_ATTRIBUTE = 'data-tl-link';
export const LINK_BOOK_URL_ATTRIBUTE = 'data-tl-link-book-url';
export const RESTORE_STATUS_ATTRIBUTE = 'data-tl-restore-status';
export const FILL_STATUS_ATTRIBUTE = 'data-tl-fill-status';

/* local storage */

export const MODULE_NAME = 'tl-price-load';
export const LOCAL_STORAGE_EXPIRE_ITEM = `${MODULE_NAME}:expire`;
export const LOCAL_STORAGE_PRICES_ITEM = `${MODULE_NAME}:prices`;
export const LOCAL_STORAGE_CACHE_VALUE = 30;
export const LOCAL_STORAGE_CACHE_MEASURE = 'minute';
export const LOCAL_STORAGE_EMPTY_CACHE = '';

// see cache measures here https://day.js.org/docs/en/manipulate/add

/* lang */

export const UNSUPPORTED_LANG = 'not-supported';
export const DEFAULT_LANG = 'en';
export const LANGUAGE_CODE_STANDARD_LENGTH = 2;

/* common */

export const DEFAULT_ARRAY_INDEX = 0;
export const DEFAULT_PRICE_DELIMITER = '.';
export const MULTI_ROOM_TYPE_DELIMITER = ',';
export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';
export const NON_BREAK_SPACE = '&nb[s]p';
export const PLACEHOLDER = {
    DateFormat: '{{DateFormat}}',
    MonthFormat: 'MMMM',
    MinLos: '{{minlos}}',
    MinLosEnd: '{{minlosDayEnd}}',
    MinLosDay: '{{minlosDay}}',
    Offer: '{{offer}}',
    Adults: '{{adults}}',
    AdultsEnd: '{{adultsEnd}}',
};
export const ROUND_OPTION = {
    No: false, // disable rounding
    RoundDown: 'RoundDown', // rounding down
    RoundUp: 'RoundUp', // rounding up
    RoundNearest: 'RoundNearest', // rounding to the nearest integer
};

/* link */

export const DEFAULT_BOOK_URL = `//${location.host}/booking/`;
export const LINK_PARAM_DELIMITER = ',';
