import {
    ADULTS_ATTRIBUTE,
    CURRENCY_ATTRIBUTE,
    DATE_ATTRIBUTE, FILL_STATUS_ATTRIBUTE,
    HOTEL_ATTRIBUTE, LINK_ATTRIBUTE, LINK_BOOK_URL_ATTRIBUTE,
    MIN_LOS_ATTRIBUTE, OFFER_CODE_ATTRIBUTE, OFFER_NAME_ATTRIBUTE, RESTORE_STATUS_ATTRIBUTE,
    ROOM_TYPE_ATTRIBUTE
} from '../const';

export class ModuleElement {
    constructor(element) {
        this._element = element;

        this._hotelId = this._element.getAttribute(HOTEL_ATTRIBUTE);
        this._roomType = this._element.getAttribute(ROOM_TYPE_ATTRIBUTE);
        this._adults = this._element.getAttribute(ADULTS_ATTRIBUTE);
        this._currency = this._element.getAttribute(CURRENCY_ATTRIBUTE);

        this._isDateField = this._element.hasAttribute(DATE_ATTRIBUTE);
        this._isMinLosField = this._element.hasAttribute(MIN_LOS_ATTRIBUTE);
        this._isOfferCodeField = this._element.hasAttribute(OFFER_CODE_ATTRIBUTE);
        this._isOfferNameField = this._element.hasAttribute(OFFER_NAME_ATTRIBUTE);
        this._isLinkField = this._element.hasAttribute(LINK_ATTRIBUTE);
        this._hasRestoreStatusAttribute = this._element.hasAttribute(RESTORE_STATUS_ATTRIBUTE);
        this._hasFillStatusAttribute = this._element.hasAttribute(FILL_STATUS_ATTRIBUTE);
    }

    getHotel() {
        return this._hotelId;
    }

    getRoomType() {
        return this._roomType;
    }

    getAdults() {
        return this._adults;
    }

    getLinkParams() {
        return this._element.getAttribute(LINK_ATTRIBUTE);
    }

    getLinkBookUrl() {
        return this._element.getAttribute(LINK_BOOK_URL_ATTRIBUTE);
    }

    getCurrency() {
        return this._currency;
    }

    setContent(code) {
        this._element.innerHTML = code;
    }

    setRestoreStatus(status) {
        this._element.setAttribute(RESTORE_STATUS_ATTRIBUTE, status.toString());
    }

    setFillStatus(status) {
        this._element.setAttribute(FILL_STATUS_ATTRIBUTE, status.toString());
    }

    setDataAttribute(data) {
        this._element.setAttribute(DATE_ATTRIBUTE, data);
    }

    setMinLosAttribute(minlos) {
        this._element.setAttribute(MIN_LOS_ATTRIBUTE, minlos);
    }

    setOfferAttribute(offer) {
        this._element.setAttribute(OFFER_NAME_ATTRIBUTE, offer);
    }

    setLink(value) {
        this._element.href = value;
    }

    isDate() {
        return this._isDateField;
    }

    isMinLos() {
        return this._isMinLosField;
    }

    isOfferCodeField() {
        return this._isOfferCodeField;
    }

    isOfferName() {
        return this._isOfferNameField;
    }

    isLink() {
        return this._isLinkField;
    }

    hasRestoreStatus() {
        return this._hasRestoreStatusAttribute;
    }

    hasFillStatus() {
        return this._hasFillStatusAttribute;
    }
}
