import {PriceInfo as PriceInfoModel} from './price-info';

const EMPTY_PRICE_ERROR = 'Empty price info';

export class Prices {
    constructor(prices) {
        this._prices = prices;
    }

    getAdultsPriceInfo(hotel, roomType, adults) {
        try {
            return this._getPriceInfo(this._prices[hotel]['rooms'][roomType]['guests'][adults]);
        } catch (err) {
            throw new Error(EMPTY_PRICE_ERROR);
        }
    }

    getRoomMinPriceInfo(hotel, roomType) {
        try {
            return this._getPriceInfo(this._prices[hotel]['rooms'][roomType]['minPrice']);
        } catch (err) {
            throw new Error(EMPTY_PRICE_ERROR);
        }
    }

    getHotelMinPriceInfo(hotel) {
        try {
            return this._getPriceInfo(this._prices[hotel]['hotelMinPrice']);
        } catch (err) {
            throw new Error(EMPTY_PRICE_ERROR);
        }
    }

    _getPriceInfo(priceInfo) {
        if (!priceInfo) {
            throw new Error(EMPTY_PRICE_ERROR);
        }

        return new PriceInfoModel(priceInfo);
    }
}
